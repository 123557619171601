import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
} from "../components/parts"

const ConstructivIQ = () => {
  return (
    <Layout title="ConstructivIQ">
      <Title heading="ConstructivIQ" sub="???" />
      <Overview project="constructiviq" text={["???"]} />

      <Project
        heading="UI design"
        sub="Design language, workflows"
        style={{ marginBottom: 0 }}
      />

      <Project heading="Staging zone" sub="???" style={{ marginBottom: 0 }} />

      <Project
        heading="Schedule integration"
        sub="????"
        style={{ marginBottom: 0 }}
      />

      <Toolset project="constructiviq" />
      <Involvement project="constructiviq" />
      <Visit project="constructiviq" />
      <WorkedWith project="constructiviq" />
    </Layout>
  )
}

export default ConstructivIQ
